<template>
    <div class="lotteryInnerCard">
        <div class="lotteryInnerCard__img">
            <img :src="require('@/img/ballicon/'+imgUrl)"/>
        </div>
        <div class="lotteryInnerCard__info">
            <div class="top">
                <div class="name">{{lotteryName}}</div>
            </div>
            <div class="bottom">
                <div class="nextDraw">距离开奖</div>
                <div class="time" v-if="lotteryDrawTime[lotteryCode] > 0">
                    
                        <div class="item" v-if="lotteryConfig[lotteryCode].lotteryType==1">{{lotteryDrawTime[lotteryCode] | reciprocalDay}}</div><span class="text" v-if="lotteryConfig[lotteryCode].lotteryType==1" >天</span>
                        <div class="item" v-if="lotteryConfig[lotteryCode].lotteryType==1">{{lotteryDrawTime[lotteryCode] | reciprocalHour}}</div><span class="text" v-if="lotteryConfig[lotteryCode].lotteryType==1">时</span>
                    
                    <div class="item">{{lotteryDrawTime[lotteryCode] | reciprocalMinute}}</div><span class="text">分</span>
                    <div class="item">{{lotteryDrawTime[lotteryCode] | reciprocalSecond}}</div><span class="text">秒</span>
                </div>
                <div class="time" v-else>
                     <div class="waitTime text"><img class="rotating" src="@/img/H5/return-1.png">开奖中...</div>
                </div>
            </div>     
        </div>
        <div class="lotteryInnerCard__drawNum">
            <!-- 以期號開獎--->
            <div class="period" v-if="periodType==1">第 <span class="text" style="padding: 0 5px;">{{periodNo}}</span> 期开奖号码
            
                <div class="item-detail" v-if="lotteryConfig[lotteryCode].lotteryType==1">
                    <span v-if="lotteryCode==='xglhc'">每周二、四、六  21:30:00开奖 </span>
                    <span v-if="lotteryCode==='qxc'">每周二、五、日  21:30:00开奖 </span>
                    <span v-if="lotteryCode==='lucky7daily'">每日22:00:00开奖 </span>
                    <span v-if="lotteryCode==='pl3'">每日21:30:00开奖 </span>
                    <span v-if="lotteryCode==='pl5'">每日21:30:00开奖 </span>
                    <span v-if="lotteryCode==='kl8lhc'">每日21:30:00开奖 </span>

                </div>
                <div v-else class="item-detail">当前<span> {{drawCount}} </span>期,剩<span> {{totalCount-drawCount}} </span>期</div>
            </div>
            <!-- 以日期開獎--->
            <div class="period" v-if="periodType==2"><span class="text">{{lastDrawTime | formateDrawTimeDate}}</span> 开奖号码</div>
            <!-- 六合彩種--->
            <LhcBall 
                v-if="lotteryCode==='xglhc'|| lotteryCode==='kl8lhc'"
                :lastDraw="lastDraw"  
                :lotteryCode="lotteryCode"
                :currentInfo="currentInfo" />
            <!-- 一般彩種--->
            <DefaultBall 
                v-else 
                :lastDraw="lastDraw" 
                :lotteryCode="lotteryCode" />
        </div>
        <div v-if="lotteryConfig[lotteryCode].liveStream && this.lotteryCode != 'kl8lhc'" class="lotteryInnerCard__vedioBtn">
            <a class="itme active" style="cursor: pointer; margin-left: 10px;" @click="openVedio">
                <div class="icon">
                    <icon name="liveStreamPlay_brown" ></icon>
                </div>
                <div class="text">
                    开奖直播
                </div>
            </a>
        </div>
      <a v-if="this.lotteryCode == 'kl8lhc'"  class="launch-kl8-website" href='https://www.cwl.gov.cn/ygkj/kjzb/' target='_blank' rel='noopener noreferrer'>
        <img src='@/img/icon_lottery.png' width='20' height='23' /> 开奖验证
      </a>
    </div>   
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import {lotteryConfig} from "@/assets/lotteryConfig.js";
import DefaultBall from "@/components/lottery_inner_card/Default_Ball"
import LhcBall from "@/components/lottery_inner_card/Lhc_Ball"
export default {
    components:{
        DefaultBall,
        LhcBall
    },
    props: {
        lotteryCode:{
            type: String,
            required: true
        }, 
        imgUrl:String,   
    },
    computed: {
        ...mapState([
            "lotteryInfo",
            "lotteryDrawTime"
        ]),
    },
    watch: {
        lotteryDrawTime: {
            handler: function(value){
                let time = value[this.lotteryCode];
                if(time)
                this.timerCountDown = time;
            },
            immediate: true,
            deep: true,
        },
        lotteryInfo: {
            handler: function(value){
                this.setLotteryInfo(this.lotteryCode);
            },
            immediate: true,
            deep: true,
        },
        lotteryCode: {
            handler: function(value){
                this.setLotteryInfo(this.lotteryCode);
            },
            immediate: true,
            deep: true,
        },
    }, 
    data() {
        return {
           periodType:1,
           lotteryName:'',
           lastDraw:'',
           periodNo:'',
           currentInfo:'',
           lastDrawTime:'',// 給periodType:2 用
           lotteryConfig:lotteryConfig,
           drawCount:0,
           totalCount:0,

        }
    },
    methods: {
        setLotteryInfo: function(lotteryCode) {
            
            if(!this.lotteryInfo.current_data) {
                return;
            }

            // 如果匹配到彩種
            let lastperiodInfo = this.lotteryInfo.current_data;
            
            if(lastperiodInfo) {  
                this.lotteryName = this._lotteryConfig[lotteryCode].name;
                this.periodNo = lastperiodInfo.period_no;
                this.lastDraw = lastperiodInfo.draw.length>0 ?  this.$options.filters.splitDrawNumber(lastperiodInfo.draw.split(","),lotteryCode) : []; 
                this.lastDrawTime = lastperiodInfo.draw_time; 
                this.currentInfo =  lastperiodInfo;     
                this.drawCount= lastperiodInfo.draw_count;
                this.totalCount=lastperiodInfo.total_count;
            } 
            
            switch(this._lotteryConfig[this.lotteryCode].displayperiodType) {
                case "foreign":
                    this.periodType = 2;
                    break;
                default:
                    this.periodType = 1;
                    break;
            }
        },
        openVedio: function(){
            this.$emit('openVedio', this.lotteryInfo);
        }
    },
}
</script>


<style lang="scss" scoped>

img{
    width: 100%;
}
.lotteryInnerCard{
    box-sizing: border-box;
    margin: 0.5rem auto .64rem auto;
    width: 14.4rem;
    padding: .25rem .15rem .25rem;
    border-radius: 10px;
    box-shadow: 0 3px 8px 0 rgba(2, 18, 52, 0.2);
    border: solid 3px #e0d6a7; 
    background: #fff;
    display: flex;
    align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    &__img{
        width: 1.5rem;
        height: 1.5rem;
    }
    &__info{
        margin:0 0.6rem 0 .45rem;
        .top{
            display: flex;
            justify-content: space-between;
            .name{
                font-size: .34rem;
                color: #0f2957;
                font-weight: 600;
            }
            .playerIocn{
                margin-left:1.92rem;
                width: .63rem;
                height: .24rem;
            }
            
        }
        .bottom{
            margin-top:.15rem ;
            display: flex;
            align-items: center;
            display: -webkit-flex;
            -webkit-align-items: center;
            .nextDraw{
                font-size: .20rem;
                color: #0f2957;
                margin-right: .12rem;
                font-weight: 700;
            }
            .time{
                border-radius: 11px;
                background: #0f2957;
                width: 3.54rem;
                height: 0.65rem;
                color: #fff;
                font-family: "Jost";
                font-size: 34px;
                font-weight: 300;
                padding: 0.11rem 0.2rem;
                box-sizing: border-box;
                display: flex;
                line-height: 42px;
                align-items: baseline;
                display: flex;
                justify-content: center;
                .waitTime{
                    font-size: 20px;
                    font-weight: 700;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    display: -webkit-flex;
                    -webkit-align-items: center;
                    img{
                        width: 20px;
                    }
                }

                
                .item{
                    width: .38rem;
                }
                span{
                    font-size: 17px;
                    margin: 0 .17rem 0 .06rem;
                    &:last-child{
                        margin-right: 0;
                    }
                }
                
            }
        }
        
    }
    &__drawNum{
        font-weight: 700;
        font-size: .22rem;
        color: #0f2957;
        width: auto;
        max-width: 695px;
        .period{
            text-align: left;.item-detail{
          font-size: 14px;
          color: #999;
          margin-left: 20px;
          letter-spacing: 1px;
          font-family: "Microsoft YaHei";
          font-weight: 500;
         

        }
            margin-bottom: .24rem;
            display: flex;
            align-items: flex-end;
            .text{
                color: #ff6819;
                font-family: "Jost";
            }

        }
    }     
}

.lotteryInnerCard__vedioBtn{
.itme {
        color: #c4c4c4;
        display: flex;
        align-items: center;
        background-color: #f0ebd0;
        padding: 2px 5px;
        border-radius: 5px;
        border: 1px solid #e0d6a7;

        .icon {
            width: 0.22rem;
            height: 0.22rem;
            margin-right: 0.02rem;
        }

        .text {
            font-weight: 600;
            font-size: 15px;
            text-align: left;
            line-height: 0.26rem;
        }

        .text.disable {
            color: #c4c4c4;
        }

        &.active {
            color: #6c5d00;
        }
    }
}
.launch-kl8-website{
  display:flex;
  background:#ec6931;border-radius:4px;
  padding:10px;
  flex-wrap:nowrap;
  color:#fff;
  font-weight:bold;
  align-items:center;
}
.launch-kl8-website img{width:auto;margin-right:10px;}
</style>