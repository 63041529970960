<template>
    <div> 
        <table>
            <tr>
                <td rowspan="2" width="193">时间</td>
                <td rowspan="2" class="change_Type" width="236">
                    <span 
                        class="btn zodiac" 
                        :class ="{'active':isShowSx}"
                        @click="changeType('sx')">
                        显示生肖
                    </span>
                    <span 
                        class="btn fivElements"
                        :class ="{'active':!isShowSx}"
                        @click="changeType('wx')">
                        显示五行
                    </span>
                </td>
                <td rowspan="2" width="73">特码</td>
                <td colspan="6" width="73">总和</td>
                <td colspan="6">特码</td>
            </tr>
            <tr>
                <td>总分</td>
                <td>总数单双</td>
                <td>总数大小</td>
                <td>七色波</td>
                <td>一肖量</td>
                <td>尾数量</td>
                <td>单双</td>
                <td>大小</td>
                <td>合单双</td>
                <td>合大小</td>
                <td>尾大小</td>
                <td>五行</td> 
            </tr>
            <!--- 歷史結果列表 --->
            <tr v-for="(item,index) in dataList" :key="index" class="history_list">
                <td class="time">{{item.period_no[0]}}期<div class="date"> {{item.draw_time[0] | formateDrawTimeDate}}</div></td>
                <td class="border-left border-right">
                    <div style="display:flex;align-items:center;justify-content:center;padding:0 4px;">
                      <div class="displayType">
                          <div class="ballWrapper">
                              <div :class="`${name} ball ball-${parseInt(num)}`"
                                  v-for="(num,index) in item.ShowNumber.slice(0,6)"
                                  :key="index">{{num | padLeft(2)}}</div>
                          </div>
                          <div class="textWrapper" v-if="item.ShowAnimal">
                              <div class="text"
                                  v-for="(sx,index) in item.ShowAnimal.slice(0,6)"
                                  :key="index+'-sx'"
                                  v-show="isShowSx"
                                  >{{sx}}
                              </div>
                              <div class="text"
                                  v-for="(wx,index) in item.ShowFiveElements.slice(0,6)"
                                  :key="index+'-wx'"
                                  v-show="!isShowSx"
                              >{{wx}}
                              </div>
                          </div>
                      </div>
                      <a @click.prevent='playDraw(item)' v-if="name === 'kl8lhc'" class='playback'>
                        <img src='@/img/icon_playback.png' />
                        回放
                      </a>
                    </div>

                </td> 
                <td class="border-left border-right" align="center"> 
                    <div class="item_wrapper">
                        <template v-if="name == 'lucky7daily'"><!--只針對英國六修正-->
                            <div v-if="item.ShowNumber[6]" :class="`${name} ball ball-${parseInt(item.ShowNumber[6])}`">{{item.ShowNumber[6] | padLeft(2)}}</div>
                        </template>
                        <template v-else>
                            <div :class="`${name} ball ball-${parseInt(item.ShowNumber[6])}`">{{item.ShowNumber[6] | padLeft(2)}}</div>
                        </template>
                        <div class="text" v-if="item.ShowAnimal"  v-show="isShowSx">
                            {{item.ShowAnimal[6]}}
                        </div>
                         <div class="text" v-if="item.ShowFiveElements"  v-show="!isShowSx">
                            {{item.ShowFiveElements[6]}}
                        </div>
                    </div>
                </td> 
                <td class="total-score">{{item.TotalScore[0]}}</td>
                <td><span :class="getUseColor(item.TotalSingleDouble[0])">{{item.TotalSingleDouble[0]}}</span></td>
                <td><span :class="getUseColor(item.TotalBigSmall[0])">{{item.TotalBigSmall[0]}}</span></td>
                <td :style="{'color':`${getUseColorClour(item.SevenColor[0])}`}">
                    {{item.SevenColor[0]}}
                </td>
                <td>{{item.AnimalCount[0]}}</td>
                <td class="border-right">{{item.TailCount[0]}}</td>
                <td><span :class="getUseColor(item.SpecialSingleDouble[0])">{{item.SpecialSingleDouble[0]}}</span></td>
                <td><span :class="getUseColor(item.SpecialBigSmall[0])">{{item.SpecialBigSmall[0]}}</span></td>
                <td><span :class="getUseColor(item.SpecialSumSingleDouble[0])">{{item.SpecialSumSingleDouble[0]}}</span></td> 
                <td><span :class="getUseColor(item.SpecialSumBigSmall[0])">{{item.SpecialSumBigSmall[0]}}</span></td>
                <td><span :class="getUseColor(item.SpecialTailBigSmall[0])">{{item.SpecialTailBigSmall[0]}}</span></td> 
                <td style="color:#0380d8">{{item.SpecialFiveElements[0]}}</td> 
            </tr>
        </table>
    </div>
    
    
</template>
<script>
export default {
    props:{
        dataList:Array,
        name:String
    },
    data(){
        return{
            isShowSx:true,  
        }
    },
    watch: {
        '$route': {
            handler: function(to, from) {
            },
            immediate: true
        } 
    },
    methods:{
        changeType(type){
            if(type == 'sx'){
                this.isShowSx = true;
            }else{
                this.isShowSx = false;
            }
        },
        getSpecialNum(string){
            return string.split(',').pop();
        },
        getUseColor(string){
            if( string === '单' || string === '小'){
                return 'color-blue'
            }
            return 'color-red'
        },
        getUseColorClour(string){
            switch(string)
            {
                case '红波':
                    return 'red';
                case '绿波':
                    return '#23b439';
                case '蓝波':
                    return '#0380d8';
                case '和局':
                    return '#646363';
                default:
                    return 'red'
            }
        },
      playDraw(item){
        const [ year, month, date ] = item.draw_time.join().split(' ')[0].split('/')
        const periodNo = item.period_no[0];
        const drawNumber = item.ShowNumber;
        const showAnimal = item.ShowAnimal;
        const showFiveElements = item.ShowFiveElements;
        this.$emit('openDrawingVideo', {year, month, date, periodNo, drawNumber, showAnimal, showFiveElements});
      }
    }
}

</script>
<style lang="scss" scoped>

/** 歷史列表title
******************************/
table{
    margin-bottom:.80rem ;
    background: #fff;
}
tr:nth-child(odd) {
    background: #f0ebd0
}
tr:nth-child(1),tr:nth-child(2){
    background: #fff;
    color: #6c5d00 ;
    height: .35rem;
    font-weight: bolder;
    td{

        border: 1px solid #e0d6a7;
    }
}
tr:nth-child(1){
    .change_Type{
        .btn{
            cursor: pointer;
            display: inline-block;
            color: #b2b2b2;
            &.active{
                color: #6c5d00;
            }
        }
        .btn:nth-child(2){
            margin: 0 15px;
        }
    }
}
/** 歷史列表
******************************/
.history_list{
    height: .73rem;
    font-size: 14px;
    color:#646363;
    border: 1px solid #e0d6a7;
    .time{
        font-family: 'Open Sans';
        .date{
            margin-top: .05rem;
        }
    }
    .displayType{
        padding: 0 2px;
        display: flex;
        flex-direction: column;
        .ballWrapper{
            display: flex;
            justify-content: space-around;
            .ball{
                @include ballSize(0.24rem);
              margin:0 2px;
            }
           
        }
        .textWrapper{
            margin-top: .05rem;
            display: flex;
            justify-content: space-around;
        }
    }
}

.item_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    .ball{
        line-height: 24px;
        color: #fff;
    }
    .text{
        margin-top: .05rem;
    } 
}


.border-left{
    border-left:1px solid #e0d6a7 ;
}
.border-right{
    border-right:1px solid #e0d6a7 ;
}
.playback{
  background:#6C5D00;
  color:#fff;
  padding:4px 2px;
  border-radius:4px;
  display:flex;
  align-items:center;
  width:57px;
  font-size:12px;
  margin-left:2px;
  cursor:pointer;
  img{margin-right:1px;}
}
</style>
