<template>
    <div>
        <!-- 樂透類型 --->
        <LotteryInnerCard
            v-if="currentLotteryName"
            :lotteryCode="currentLotteryName" 
            :imgUrl="imgUrl"
            @openVedio="openVedio"
        />
        
        <!--- 主選單 --->
        <div class="lottoy_menu_wraper">
            <div class="lottoy_menu">
                <div class="menu_btn"
                    :class="{'active':key=='history'}"
                    v-for="(item, key) in primaryMenu" 
                    :key="key"
                    @click="changeTab(key)"
                    v-if="item.iconName != ''">
                    <icon :name="item.iconName" class="menu_icon" :class="{'active':key=='history'}"></icon>
                    <div>{{item.name}}</div> 
                    <!-- <div class="line"></div> -->
                </div>
            </div>
        </div>

        <!--- 顯示結果 --->
        <div class="result">
            <div class="result_menu"></div>
            <div class="result_display">
                <History v-if="currentTab=='history'" @openDrawingVideo="openDrawingVideo"  />
            </div>  
        </div>
        <!--視訊-->
        <div v-if="showVedio">
            <pcVideoLive @closeVedio="closeVedio" :lotteryType="this.currentLotteryName" :lotteryInfo="lotteryInfo"></pcVideoLive>
        </div>

      <div v-if="showDrawingVideo">
        <DrawingVideo @closeDrawingVideo="closeDrawingVideo" :year='year' :month='month' :date='date' :periodNo='periodNo' :drawNumber='drawNumber' :showAnimal='showAnimal' :showFiveElements='showFiveElements' />
      </div>
    </div>
</template>
<script>
import LotteryInnerCard from "@/components/lottery_inner_card/Lottery_Inner_Card";
import History from "@/components/lottery_result/History_Index";
import pcVideoLive from "@/components/live_stream/pcVideoLive";
import DrawingVideo from "@/components/live_stream/DrawingVideo";

export default {
    name:"Lottery",
    components:{
        LotteryInnerCard,
        History,
        pcVideoLive,
      DrawingVideo
    },
    data(){
        return{
            imgUrl:'',
            currentLotteryName:'',
            primaryMenu:{},
            secondaryMenu:[],
            currentTab:"history", 
            secondaryMenuOptionIndex:0,
            showVedio: false,
            showDrawingVideo: false,
            lotteryInfo: '',
          year:'',
          month:'',
          date:'',
          periodNo:'',
          drawNumber: [],
          showAnimal: [],
          showFiveElements: []
        }
    },
    watch: {
        '$route': {
            handler: function(to, from) {
               this.reflashLotteryCard();
            },
            immediate: true
        } 
    },
    methods:{
        async init(){
            //console.log(this.currentLotteryName);
            if(!this._lotteryConfig[this.currentLotteryName]){
               alert("無此彩種")
            }
            // 獲取當前彩種圖片url
            this.imgUrl = this._lotteryConfig[this.currentLotteryName].imgUrl;
            this.getPrimaryMenu()
            this.getSecondaryMenu(this.currentTab)   
        },
        // 獲取主選單
        getPrimaryMenu(){
            this.primaryMenu = this._lotteryConfig[this.currentLotteryName].mainMenu;
            //console.log('this.primaryMenu ',this.primaryMenu )
        },
        // 獲取子選單
        getSecondaryMenu(tabName){
            this.secondaryMenu = this._lotteryConfig[this.currentLotteryName].mainMenu[tabName].subMenu;
            //console.log(this.secondaryMenu)
        },
        // 切換主選單
        changeTab(tabName){

            // 到時要拿掉
            if(tabName !='history') return

            // 只要切換主選單，全部從置
            if(this.currentTab != tabName){
                this.secondaryMenuOptionIndex = 0;
            }
            this.currentTab = tabName;
            this.getSecondaryMenu(tabName)
        },
        // 切換子選單
        changeSubMenu(index){
            this.secondaryMenuOptionIndex = index;
        },
        reflashLotteryCard() {
            this.currentLotteryName = this.$route.params.name;
            //console.log('currentLotteryName',this.currentLotteryName)     
            this.init();
        },
        openVedio(info){
            this.lotteryInfo = info;
            this.showVedio = true;
        },
        closeVedio(){
            this.showVedio = false;
        },
      openDrawingVideo({year, month, date, periodNo, drawNumber, showAnimal, showFiveElements}){
        this.year = year;
        this.month = month;
        this.date = date;
        this.periodNo = periodNo;
        this.drawNumber = drawNumber;
        this.showAnimal = showAnimal;
        this.showFiveElements = showFiveElements;
        this.showDrawingVideo = true;
      },
      closeDrawingVideo(){
        this.showDrawingVideo = false;
      },
    },
    mounted(){
        this.reflashLotteryCard();
    }
    
}
</script>
<style lang="scss" scoped>

// lottoy_menu
.lottoy_menu_wraper{
    max-width: 1440px;
    margin: 0.64rem auto 0.03rem auto;
    box-sizing: border-box;
    position: relative;
    .lottoy_menu{
        display: flex;
        height: .22rem;
        position:absolute;
        .menu_btn{
            color: #c4c4c4;
            font-size: .17rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            display: -webkit-flex;
            -webkit-align-items: center;
            background-color: #e0d6a7;
            height: 35px;
            padding: 5px 10px;
            border-radius: 10px 10px 0 0;
            .menu_icon{
                width: .22rem;
                height: .22rem;
                color: #c4c4c4;
                margin-right:.03rem ;
                &.active{
                    color: #6c5d00;
                }   
            }
            &.active{
                color: #6c5d00;
            } 
            .line{
                width: 1px;
                height: 14px;
                background: #ccc;
                margin: 0 .16rem;
            }
        }        
    }
   
    
}
       


// lottoy_submenu
.lottoy_subMenu{
    max-width: 1440px;
    margin: 0 auto;
    border: 1px solid #dadada;
    background: #fff;
    font-size: 0.18rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    // justify-content:space-between;
    padding: 0.2rem 0.3rem;
   
    .subMenu{
        display: inline-block;
        color: #2e2e2e;
        padding: 0.1rem;

    }
    .active{
        color: coral;
        border: 1px solid coral;
    }
}

.result{
    margin: 0 auto;
    width: 1440px;
    .result_menu{
        // background: #ccc;
        // width: 100%;
        // height: 100px;
    }
    .result_display{
        
    }
}

// 先暫時放者
.lottoy_menu{
    .menu_btn:nth-child(2),
    .menu_btn:nth-child(3),
    .menu_btn:nth-child(4),
    .menu_btn:nth-child(5){
        cursor: auto;
    }
}




</style>
