<template>
    <div class="content">
        <table>
            <!--- 歷史結果標題 --->
            <tr class="header-title">
                <td class="time">时间</td>
                <td class="period">期数</td>
                <td class="result">开奖号码</td>
                <td class="sum">佰拾和</td>
                <td class="sum">佰个和</td>
                <td class="sum">拾个和</td>
                <td class="sum">总和</td>
            </tr>
            <!--- 歷史結果列表 --->
            <tr v-for="(item,index) in dataList" :key="index" class="history_item">
                <td class="bzh">{{item.draw_time[0]| formateDrawTimeAll}}</td>
                <td class="bzh">{{item.period_no[0]}}</td>
                <td class="bzh">
                    <div class="ball-wrapper"> 
                        <div :class="`${name} ball ball-${parseInt(num)} solid`"  v-for="(num,index) in item.ShowNumber" :key="index">{{num}}</div> 
                    </div>
                </td>
                <td class="bzh sum_result" v-if="item.HundredTenSum">
                    <span>{{item.HundredTenSum[0]}}</span>
                    <span :class="getUseColor(item.HundredTenSum[1])">{{item.HundredTenSum[1]}}</span>
                    <span :class="getUseColor(item.HundredTenSum[2])">{{item.HundredTenSum[2]}}</span>
                </td>
                  <td class="bgh sum_result" v-if="item.HundredOneSum">
                    <span>{{item.HundredOneSum[0]}}</span>
                    <span :class="getUseColor(item.HundredOneSum[1])">{{item.HundredOneSum[1]}}</span>
                    <span :class="getUseColor(item.HundredOneSum[2])">{{item.HundredOneSum[2]}}</span>
                </td>
                <td class="zgh sum_result" v-if="item.TenOneSum">
                    <span>{{item.TenOneSum[0]}}</span>
                    <span :class="getUseColor(item.TenOneSum[1])">{{item.TenOneSum[1]}}</span>
                    <span :class="getUseColor(item.TenOneSum[2])">{{item.TenOneSum[2]}}</span>
                </td>
                <td class="total sum_result" v-if="item.TotalSum">
                    <span>{{item.TotalSum[0]}}</span>
                    <span :class="getUseColor(item.TotalSum[2])">{{item.TotalSum[2]}}</span>
                    <span :class="getUseColor(item.TotalSum[1])">{{item.TotalSum[1]}}</span>
                </td>
            </tr>
        </table>
       
    </div>
</template>
<script>
export default {
    props:{
        dataList:Array,
        name:String
    },
    watch: {
        '$route': {
            handler: function(to, from) {
            },
            immediate: true
        } 
    },
    methods:{
        getUseColor(string){
            if( string === '单' || string === '小' || string === '尾小'){
                return 'color-blue'
            }
            return 'color-red'
        },
    } 
}
</script>
<style lang="scss" scoped>
.content{
    display: flex;
    justify-content: space-between;
     margin-bottom: .8rem;
    /*** 歷史列表 table ***/
    table{
        background: #fff;
        /*** 歷史列表 title ***/
        .header-title{
            font-weight: 700;
            background: #fff;
            color: #6c5d00;
            height: .7rem;
            font-size: .17rem;
            td{
                border: 1px solid #e0d6a7;
            }
            .time,.period{
                width: 1.7rem;
            }
            .result{
                width: 2.66rem;
            }
            .sum{
                width: 2.1rem;
            }
        }
        /*** 歷史列表 item ***/
        .history_item{
            height: .73rem;
            font-size: 14px;
            color: #646363;
            border: 1px solid #e0d6a7;
            &:nth-child(even) {
                background: #f0ebd0
            }
            .ball-wrapper{
                display: flex;
                justify-content: center;
                .ball{
                    @include ballSize(0.45rem);     
                    margin-right: .24rem;
                    font-size:.3rem;
                }
            }
            .sum_result{
                span{
                    width: .7rem;
                    display: inherit;
                    &:nth-child(2), &:nth-child(3){
                        font-weight: 900;
                    }
                }
            }  
        }
    }  
}
.bzh,.bgh,.zgh,.total{
    border-left:1px solid #e0d6a7;
}

</style>
