<template>
    <div class="content">
        <table>
            <!--- 歷史結果標題 --->
             <thead>
                <tr class="header-title">
                   <td class="time">时间</td>
                   <td class="period">期数</td>
                   <td class="result">
                       <div class="container" v-if="dataList[0] && (dataList[0].ShowBigSmall || dataList[0].ShowSingleDouble || dataList[0].ShowParallel)">
                            <button class="changeBtn" :class="{ active: resultType==0  }" @click="resultType=0">显示号码</button>
                            <button v-if="dataList[0] && dataList[0].ShowBigSmall" class="changeBtn" :class="{ active: resultType==1 }" @click="resultType=1">显示大小</button>
                            <button v-if="dataList[0] && dataList[0].ShowSingleDouble" class="changeBtn" :class="{ active: resultType==2 }" @click="resultType=2">显示单双</button>
                            <button v-if="dataList[0] && dataList[0].ShowParallel" class="changeBtn" :class="{ active: resultType==3 }" @click="resultType=3">显示对子</button>

                       </div>
                       <template v-else>显示号码</template>
                    </td>
                    <td v-if="dataList[0] && dataList[0].TotalSum" class="zh">总和</td>
                    <td v-if="dataList[0] && dataList[0].DragonTiger" class="lh">龙虎</td>
                    <!-- -->
                    <td v-if="dataList[0] && dataList[0].TailBigSmall" class="zh">尾大小</td>
                    <td v-if="dataList[0] && dataList[0].OneAndFourDragonTiger" class="zh">1~4龙虎</td>
                    
                    <!-- -->
                    <td v-if="dataList[0] && dataList[0].TopTwiceSum" class="zh">冠亚军和</td>
                    <td v-if="dataList[0] && dataList[0].OneAndFiveDragonTiger" class="zh">1~5龙虎</td>
                    <!-- -->
                    <td v-if="dataList[0] && dataList[0].FishShrimpAndCrab" class="zh">鱼蟹虾</td>
                    <!-- -->
                    <td v-if="dataList[0] && dataList[0].TopThree" class="lh">前三</td>
                    <td v-if="dataList[0] && dataList[0].MiddleThree" class="lh">中三</td>
                    <td v-if="dataList[0] && dataList[0].LastThree" class="lh">后三</td>

                    <!-- -->
                    <td v-if="dataList[0] && dataList[0].SingleDoubleTie" class="lh">单双</td>
                    <td v-if="dataList[0] && dataList[0].FrontBackendTie" class="lh">前后</td>
                    <td v-if="dataList[0] && dataList[0].MultiMarkup" class="lh">总和组合</td>
                    <td v-if="dataList[0] && dataList[0].FiveElements" class="lh">五行</td>
                </tr>
            </thead>
            <!--- 歷史結果列表 --->
            <tr v-for="(item,index) in dataList" :key="index" class="history_item">
                <td>{{item.draw_time[0] | formateDrawTimeAll}}</td>
                <td>{{item.period_no[0]}}</td>
                <td>
                    <div v-if="resultType==0" :class="`ball-wrapper ${LotteryTwenty.includes(name) ? 'twentyBall' : ''} `"> 
                        <div :class="`${name} ball ball-${parseInt(num)} ${LotteryTwenty.includes(name) ? 'twentyBall' : ''}  solid `"  v-for="(num,index) in $options.filters.splitDrawNumber(item.ShowNumber,name)" :key="index">
                            <span v-if="padLeftLottery.includes(name)">{{num | padLeft(2)}}</span>
                            <span v-else-if="padLeftLottery2.includes(name)">{{parseInt(num)}}</span>
                            <span v-else>
                                {{num}}
                            </span>
                        </div> 
                    </div>
                    <div v-if="resultType==1" :class="`ball-wrapper ${LotteryTwenty.includes(name) ? 'twentyBall' : ''} `">
                        <div  v-for="(option,index) in item.ShowBigSmall" :key="index" :class="`ball ${styleFilter(option)}`" >
                            <span>{{option}}</span>
                        </div>
                    </div>
                    <div v-if="resultType==2" :class="`ball-wrapper ${LotteryTwenty.includes(name) ? 'twentyBall' : ''} `">
                        <div  v-for="(option,index) in item.ShowSingleDouble" :key="index" :class="`ball ${styleFilter(option)}`" >
                            <span>{{option}}</span>
                        </div>
                    </div>
                     <div v-if="resultType==3" :class="`ball-wrapper ${LotteryTwenty.includes(name) ? 'twentyBall' : ''} `"> 
                        <div :class="` box   ${item.ShowParallel.includes(num) ? `${name} ball  ball-${parseInt(num)} color-white` : 'color-black' }      ${LotteryTwenty.includes(name) ? 'twentyBall' : ''}  solid `"  v-for="(num,index) in $options.filters.splitDrawNumber(item.ShowNumber,name)" :key="index">
                            <span v-if="padLeftLottery.includes(name)">{{num | padLeft(2)}}</span>
                            <span v-else-if="padLeftLottery2.includes(name)">{{parseInt(num)}}</span>
                            <span v-else>
                                {{num}}
                            </span>
                        </div> 
                    </div>
                </td>
                <td v-if="dataList[0] && dataList[0].TotalSum" style="position: relative;">
                    <div class="internal-wrapper">
                        <table class="internal" >
                            <tr>
                            <td v-for="(option,index) in item.TotalSum" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                            </tr>
                        </table>
                     </div>
                </td>
                 <td v-if="dataList[0] && dataList[0].DragonTiger" style="position: relative;">
                     <div class="internal-wrapper">
                    <table cellspacing="0px" width="100%"  class="internal" >
                        <tr>
                            <td v-for="(option,index) in item.DragonTiger" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                        </tr>
                    </table>
                     </div>
                </td>

                <td v-if="dataList[0] && dataList[0].TopTwiceSum" style="position: relative;">
                    <div class="internal-wrapper">
                    <table cellspacing="0px" width="100%"  class="internal" >
                        <tr>
                            <td v-for="(option,index) in item.TopTwiceSum" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                        </tr>
                    </table>
                    </div>
                </td>
               <td v-if="dataList[0] && dataList[0].OneAndFiveDragonTiger" style="position: relative;">
                   <div class="internal-wrapper">
                    <table cellspacing="0px" width="100%"  class="internal" >
                        <tr>
                            <td v-for="(option,index) in item.OneAndFiveDragonTiger" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                        </tr>
                    </table>
                   </div>
                </td>
                <td v-if="dataList[0] && dataList[0].FishShrimpAndCrab" style="position: relative;">
                     <div class="internal-wrapper">
                        <table cellspacing="0px" width="100%"  class="internal" >
                            <tr>
                                <td v-for="(option,index) in item.FishShrimpAndCrab" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                            </tr>
                        </table>
                     </div>
                </td>

                 <td v-if="dataList[0] && dataList[0].TopThree" style="position: relative;">
                     <div class="internal-wrapper">
                    <table cellspacing="0px" width="100%"  class="internal" >
                        <tr>
                            <td v-for="(option,index) in item.TopThree" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                        </tr>
                    </table>
                     </div>
                </td>
                <td v-if="dataList[0] && dataList[0].MiddleThree" style="position: relative;">
                    <div class="internal-wrapper">
                    <table cellspacing="0px" width="100%"  class="internal" >
                        <tr>
                            <td v-for="(option,index) in item.MiddleThree" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                        </tr>
                    </table>
                    </div>
                </td>
                <td v-if="dataList[0] && dataList[0].LastThree" style="position: relative;">
                    <div class="internal-wrapper">
                    <table cellspacing="0px" width="100%"  class="internal" >
                        <tr>
                            <td v-for="(option,index) in item.LastThree" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                        </tr>
                    </table>
                    </div>
                </td>
                <td v-if="dataList[0] && dataList[0].SingleDoubleTie" style="position: relative;">
                    <div class="internal-wrapper">
                    <table cellspacing="0px" width="100%"  class="internal" >
                        <tr>
                            <td v-for="(option,index) in item.SingleDoubleTie" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                        </tr>
                    </table>
                    </div>
                </td>
                <td v-if="dataList[0] && dataList[0].FrontBackendTie" style="position: relative;">
                    <div class="internal-wrapper">
                    <table cellspacing="0px" width="100%"  class="internal" >
                        <tr>
                            <td v-for="(option,index) in item.FrontBackendTie" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                        </tr>
                    </table>
                    </div>
                </td>
                <td v-if="dataList[0] && dataList[0].MultiMarkup" style="position: relative;">
                    <div class="internal-wrapper">
                    <table cellspacing="0px" width="100%"  class="internal" >
                        <tr>
                            <td v-for="(option,index) in item.MultiMarkup" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                        </tr>
                    </table>
                    </div>
                </td>
                <td v-if="dataList[0] && dataList[0].FiveElements" style="position: relative;">
                    <div class="internal-wrapper">
                    <table cellspacing="0px" width="100%"  class="internal" >
                        <tr>
                            <td v-for="(option,index) in item.FiveElements" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                        </tr>
                    </table>
                    </div>
                </td>
                <td v-if="dataList[0] && dataList[0].TailBigSmall" style="position: relative;">
                    <div class="internal-wrapper">
                    <table cellspacing="0px" width="100%"  class="internal" >
                        <tr>
                            <td v-for="(option,index) in item.TailBigSmall" :key="index" :class="`${$options.filters.drawsFilter(option.slice(1))}`">{{option.slice(1)}}</td>
                        </tr>
                    </table>
                    </div>
                </td>
                <td v-if="dataList[0] && dataList[0].OneAndFourDragonTiger" style="position: relative;">
                    <div class="internal-wrapper">
                    <table cellspacing="0px" width="100%"  class="internal" >
                        <tr>
                            <td v-for="(option,index) in item.OneAndFourDragonTiger" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                        </tr>
                    </table>
                    </div>
                </td>
            </tr>

        </table>
    </div>
</template>
<script>
export default {
    props:{
        dataList:Array,
        name:String,
    },
     data() {
         return {
            padLeftLottery: ['azxy8','jisukuaile','lucky7daily', 'infinity8', 'infinity8ex', 'cannon20', 'cannon20ex', 'lucky5', 'lucky5ex', 'xglhc', 'kl8lhc'],
            LotteryTwenty:['cannon20','azxy20','cannon20ex'],
            padLeftLottery2: ['azxy10','azxy20','jisusaiche'],
            resultType:0
        }
     },
    methods:{
         styleFilter: function (value) {
            if(value=='单' || value == '小'){
                return 'lightblue';
            }else if(value=='双' || value == '大'){
                return 'blue';
            }else{
                 return 'yellow';
            }
        }
    },
    watch: {
        '$route': {
            handler: function(to, from) {
              this.resultType=0;
            },
            immediate: true
        } 
    }
}
</script>
<style lang="scss" scoped>
.content{
    margin-bottom: .8rem;
    display: flex;
    justify-content: space-between;
    background: #fff;
    /*** 歷史列表 table ***/
    table{
        
        td{
            border: 1px solid #e0d6a7;
        }
        /*** 歷史列表 title ***/
        .header-title{
            font-weight: 700;
            background: #fff;
            color: #6c5d00;
            height: .7rem;
            font-size: .17rem;
            
            .time,.zh{
                width: 200px;
            }
           

            .period{
                width: 180px;
            }
            
            .result{ 
               width: 550px;
                .changeBtn{
                    margin: 0 10px;
                    height: 41px;
                    width: 122px;
                    border: 0;
                    border-radius: 5px;
                    font-size: 18px;
                    font-weight: bold;
                    background-color: #f0ead0;
                    color: #6c5d00;
                    cursor: pointer;

                    &.active{
                        background-color: #6c5d00;
                        color: #fff;
                    }
                }
            }
            .sum{
                width: 4.46rem;
            }
        }
        /*** 歷史列表 item ***/
        .history_item{
            height: .73rem;
            font-size: 14px;
            color: #646363;
            border: 1px solid #e0d6a7;
            &:nth-child(even) {
                background: #f0ebd0
            }
            .ball-wrapper{
                display: flex;
                flex-flow: wrap;
                justify-content: center;
                grid-template-columns: auto;
                padding: 10px 0;
                div{
                   margin: 0 5px;
                }
                &.twentyBall{
                    justify-content: stretch;
                    display: inline-grid;
                    grid-template-columns: repeat(11, 1fr);
                    gap: 10px;
                    grid-gap: 10px;
                    padding: 10px;
                    div{
                     margin: 0;
                    }
                }
                .ball{
                    @include ballSize(0.45rem);     
                    font-size:.3rem;
                    color:#fff;
                }
                .box{
                     @include ballSize(0.45rem);   
                    line-height: 0.45rem;
                    font-size:.3rem;
                    border-radius: 5px;
                }
            }
            
            .sum_result{
                span{
                    font-weight: 600;
                    width: 50%;
                    display: inline-block;
                }
            }  
             .twentyBall.ball:nth-child(10) {
                    grid-column: span 2;
                }
            .twentyBall.ball{
                    grid-column: span 1;
                                
                }
            .internal{
                table-layout: fixed;
                height: 100%;
                table-layout: fixed;
                border: none;
                border-collapse: collapse;
                width:100%;

            }
            .internal-wrapper {
            position: absolute;
            height: 100%;
            top: 0;
                td {
                    width: 1%;
                }
            }

        }
    }  
}


</style>
