<template>
    <div>
        <div class="paginate_wrapper">
            <paginate
                ref="pager"
                :page-count="pageToatl"
                :click-handler="clickPage"
                :prev-link-class="'prevLink'"
                :disabled-class="'disabled'"
                :active-class="'active'"
                :next-link-class="'nextLink'"
                :page-link-class="'link'"
                :page-class="'item'"
                prev-text=""
                next-text=""
                :container-class="'paginateStyle'">
            </paginate>
        </div>
        <!--- 六合彩 ---> 
        <Lhc
            v-if="currentHistoryName == 'xglhc' || currentHistoryName == 'sgplhc'|| currentHistoryName == 'kl8lhc'"
            :dataList="dataList"
            :name="currentHistoryName"
            @openDrawingVideo="openDrawingVideo"
        />
        <!--- 七星彩 ---> 
        <Qxc v-if="currentHistoryName == 'qxc'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 美國強力球 ---> 
        <Powerball v-if="currentHistoryName == 'powerball'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 美國超級百萬 ---> 
        <UsSuperMillions v-if="currentHistoryName == 'megamillions'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 排列3 ---> 
        <Pl3 v-if="currentHistoryName == 'pl3'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 排列5---> 
        <Pl5 v-if="currentHistoryName == 'pl5'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 幸運五星彩 ---> 
        <Classic v-if="currentHistoryName == 'penta5classic'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 英国飞艇 ---> 
        <Classic v-if="currentHistoryName == 'surfing10classic'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 英国赛车 Rolling10 ---> 
        <Classic v-if="currentHistoryName == 'rolling10'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 英国时时彩 Penta5 ---> 
        <Classic v-if="currentHistoryName == 'penta5'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 英国快乐彩 Infinity8 ---> 
        <Classic v-if="currentHistoryName == 'infinity8'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 英国快乐8 Cannon20 ---> 
        <Classic v-if="currentHistoryName == 'cannon20'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 英国11选5 Lucky5 ---> 
        <Classic v-if="currentHistoryName == 'lucky5'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 英国快三 Gamma3 ---> 
        <Classic v-if="currentHistoryName == 'gamma3'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 英国极速赛车 Rolling10Ex ---> 
        <Classic v-if="currentHistoryName == 'rolling10ex'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 英国极速时时彩 Penta5Ex ---> 
        <Classic v-if="currentHistoryName == 'penta5ex'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 英国极速快乐彩 Infinity8Ex ---> 
        <Classic v-if="currentHistoryName == 'infinity8ex'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 英国极速快乐8 Cannon20Ex ---> 
        <Classic v-if="currentHistoryName == 'cannon20ex'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 英国极速11选5 Lucky5Ex ---> 
        <Classic v-if="currentHistoryName == 'lucky5ex'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 英国极速快三 Gamma3Ex ---> 
        <Classic v-if="currentHistoryName == 'gamma3ex'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 英国天天彩 Lucky7Daily ---> 
        <Lhc v-if="currentHistoryName == 'lucky7daily'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 英國排列5 pl5Daily---> 
        <Pl5 v-if="currentHistoryName == 'pl5daily'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 澳洲彩 azxy5 ---> 
        <Classic v-if="currentHistoryName == 'azxy5'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 澳洲彩 azxy8 ---> 
        <Classic v-if="currentHistoryName == 'azxy8'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 澳洲彩 azxy10 ---> 
        <Classic v-if="currentHistoryName == 'azxy10'" :dataList="dataList" :name="currentHistoryName"/>
         <!--- 澳洲彩 azxy20 ---> 
        <Classic v-if="currentHistoryName == 'azxy20'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 澳洲彩 jisukuai3 ---> 
        <Classic v-if="currentHistoryName == 'jisukuai3'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 澳洲彩 jisusaiche ---> 
        <Classic v-if="currentHistoryName == 'jisusaiche'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 澳洲彩 jisushishi ---> 
        <Classic v-if="currentHistoryName == 'jisushishi'" :dataList="dataList" :name="currentHistoryName"/>
        <!--- 澳洲彩 jisukuaile ---> 
        <Classic v-if="currentHistoryName == 'jisukuaile'" :dataList="dataList" :name="currentHistoryName"/>

    </div> 
</template>
<script>
import store from "@/store";
import { mapState, mapGetters, mapActions } from 'vuex';
import { getLotteryHistory ,getLotteryHistoryStatistics} from '@/api/api';
import Lhc from "@/components/lottery_result/history/Lhc";
import Qxc from "@/components/lottery_result/history/Qxc";
import Pl3 from "@/components/lottery_result/history/Pl3";
import Pl5 from "@/components/lottery_result/history/Pl5";
import Classic from "@/components/lottery_result/history/Classic";
export default {
    components:{
        Lhc,
        Qxc,
        Pl3,
        Pl5,
        Classic
    },
    data(){
        return{
            currentHistoryName:'',
            dataList:[],
            pageIndex:1, // 當前頁數
            pageSize:20, // 每頁顯示幾筆
            records: 0,  // 總資料筆數
            pageToatl:0,  // 總頁數
            callHistory: false,
        }
    },
     computed: {
        ...mapState([
            "lotteryDrawTime"
        ]),
    },
    watch: {
        '$route': {
            handler: function(to, from) {
               this.currentHistoryName = to.params.name;
               this.pageIndex = 1;
               this.dataList=[];
               this.getHistoryInfo();
               this._lotteryUtil.changeLottery();
               let pager = this.$refs.pager;
               if(pager) {
                   pager.innerValue = 1;
               }    
            },
            immediate: true
        } 
    },
    methods:{
        async getHistoryInfo(){
            // 獲取彩種
            try{ 
                let name = this.currentHistoryName;
                const result = await getLotteryHistoryStatistics(name,this.pageIndex,'');
                this.dataList = result.data.statistics;
                this.pageToatl = Math.ceil(result.data.record_count / this.pageSize);     
            }catch(e){
                
            }
        },
        clickPage(num){
            this.pageIndex = num;
            this.getHistoryInfo();
        },
      openDrawingVideo(item){
        this.$emit('openDrawingVideo', item);
      }
    },
    mounted(){
        this.currentHistoryName = this.$route.params.name;
        this.timer = setInterval(()=>{
            
            if(this.lotteryDrawTime[this.currentHistoryName] <= 0 ) {
                this.callHistory=true;
            }
            if(this.callHistory) {
                this.callHistory=false;
                this.getHistoryInfo(); 
                if(this.lotteryDrawTime[this.currentHistoryName] <= 0 ) {
                    this._lotteryUtil.changeLottery();
                }
            }
        }, 3000) 
    } 
}
</script>
<style lang="scss" scoped>

.paginate_wrapper{
    margin-bottom: 7px;
    display: flex;
    justify-content: flex-end;
    .paginateStyle li.item a.link{
        color:#6c5d00 ;
    }
}
</style>
