<template>
    <div> 
        <table>
            <tr class="header__title">
                <td class="time">时间</td>
                <td class="period">期号</td>
                <td class="drawNum">开奖号码</td>
                <td class="result">总和</td>
            </tr>
            <!--- 歷史結果列表 --->
            <tr v-for="(item,index) in dataList" :key="index" class="history__item">
                <td class="bzh">{{item.draw_time[0] | formateDrawTimeAll}}</td>
                <td class="bzh">{{item.period_no[0]}}</td>
                <td class="bzh"> 
                    <div class="ball__wrapper"> 
                        <div :class="`${name} ball ball-${num} solid`"  v-for="(num,index) in item.ShowNumber" :key="index">{{num}}</div> 
                        <!-- <div class="ball"  v-for="(num,index) in item.draw.split(',')" :key="index">{{num}}</div>  -->
                    </div>
                </td>
                <td class="bzh">
                    <div class="sum__wrapper">
                        <div class="text">{{item.TotalSum[0]}}</div>
                        <div class="text"  :class="`${$options.filters.drawsFilter(item.TotalSum[1])}`">{{item.TotalSum[1]}}</div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    props:{
        dataList:Array,
        name:String
    },
    watch: {
        '$route': {
            handler: function(to, from) {
            },
            immediate: true
        } 
    },
   
}

</script>
<style lang="scss" scoped>
table{
    background: #fff;
    margin-bottom: .8rem;
}
.header__title{
    background: #fff;
    color: #6c5d00;
    height: .7rem;
    font-weight: 600;
    font-size: .17rem;
    td{
        border: 1px solid #e0d6a7;
    }
    .time{
        width: 2.15rem;
    }
    .period{
        width: 2.15rem;
    }
    .drawNum{
        width: 5.67rem;
    }
    .result{
        width: 4.46rem;
    }
}


/** 歷史列表
******************************/
.history__item{
    height: .73rem;
    font-size: 14px;
    color: #646363;
    border: 1px solid #e0d6a7;
    &:nth-child(even) {
        background: #f0ebd0;
        border-right: 1px solid #f0ebd0;
    }
    .ball__wrapper{
        display: flex;
        justify-content: center;
        .ball{
            @include ballSize(0.45rem);     
            margin-right: .24rem;
            font-size:.3rem;
            color: #fff;
        }
    }
    .sum__wrapper{
        display: flex;
        .text{
           
            width: 50%;
            &:last-child{
                font-weight: 600;
            }
        }
        
    }  
}

.bzh,.bgh,.zgh,.total{
    border-left:1px solid #e0d6a7;
}

</style>
