<template>
    <div class="content">
        <table>
            <!--- 歷史結果標題 --->
            <tr class="header-title">
                <td class="time">时间</td>
                <td class="period">期数</td>
                <td class="result">开奖号码</td>
                <td class="sum">总和</td>
            </tr>
            <!--- 歷史結果列表 --->
            <tr v-for="(item,index) in dataList" :key="index" class="history_item">
                <td  class="bzh">{{item.draw_time[0] | formateDrawTimeAll}}</td>
                <td  class="bzh">{{item.period_no[0]}}</td>
                <td  class="bzh" v-if="item.ShowNumber">
                    <div class="ball-wrapper"> 
                        <div :class="`${name} ball ball-${parseInt(num)} solid`"  v-for="(num,index) in item.ShowNumber" :key="index">{{num}}</div> 
                    </div>
                </td>
                <td class="total sum_result" v-if="item.TotalSum">
                    <span>{{item.TotalSum[0]}}</span>
                    <span  :class="`${$options.filters.drawsFilter(item.TotalSum[1])}`">{{item.TotalSum[1]}}</span>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    props:{
        dataList:Array,
        name:String
    },
    watch: {
        '$route': {
            handler: function(to, from) {
            },
            immediate: true
        } 
    },
}
</script>
<style lang="scss" scoped>
.content{
    margin-bottom: .8rem;
    display: flex;
    justify-content: space-between;
    /*** 歷史列表 table ***/
    table{
        background: #fff;
        /*** 歷史列表 title ***/
        .header-title{
            font-weight: 700;
            background: #fff;
            color: #6c5d00;
            height: .7rem;
            font-size: .17rem;
            td{
                border: 1px solid #e0d6a7;
            }
            .time,.period{
                width: 2.15rem;
            }
            .result{
                width: 5.67rem;
            }
            .sum{
                width: 4.46rem;
            }
        }
        /*** 歷史列表 item ***/
        .history_item{
            height: .73rem;
            font-size: 14px;
            color: #646363;
            border: 1px solid #e0d6a7;
            &:nth-child(even) {
                background: #f0ebd0
            }
            .ball-wrapper{
                display: flex;
                justify-content: center;
                .ball{
                    @include ballSize(0.45rem);     
                    margin-right: .24rem;
                    font-size:.3rem;
                }
            }
            .sum_result{
                span{
                    font-weight: 600;
                    width: 50%;
                    display: inline-block;
                }
            }  
        }
    }  
}
.bzh,.bgh,.zgh,.total{
    border-left:1px solid #e0d6a7;
}


</style>
