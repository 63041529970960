<template>
    <div >
      
        <div class="drawballs">
            <div :class="`${lotteryCode} ball ball-${parseInt(item)}`" v-for="(item,index) in lastDraw.slice(0, 6)" :key="index">{{item | padLeft(2)}}</div>
            
            <div class="add">+</div>
            <div :class="`${lotteryCode} ball ball-${parseInt(lastDraw[6])}`">{{lastDraw[6] | padLeft(2)}}</div>  
        </div>
        <div class="mappingBall_sx" v-if="currentInfo">
            <div class="item" v-for="(item,index) in currentInfo.analysis.slice(0,7)" :key="index">{{item}}</div>
        </div>
        <div class="mappingBall_wx" v-if="currentInfo">
            <div class="item" v-for="(item,index) in currentInfo.analysis.slice(7,14)" :key="index">{{item}}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        lotteryCode:{
            type: String,
            required: true
        }, 
        lastDraw:Array | [],
        currentInfo:null   
    },
}
</script>
<style lang="scss" scoped>
.drawballs{
    display: flex;
    margin-bottom: .11rem;
    .ball{
        margin-right: .16rem;
        color: #fff;
        font-size: .34rem;
        @include ballSize(.6rem);
        &:nth-child(6){
            margin-right: 0;
        }
    }     
}
.mappingBall_sx,
.mappingBall_wx{
    display: flex;
    .item{
        width: .6rem;
        line-height: .28rem;
        font-size: .17rem;
        color: #646363;
        margin-right: .16rem;
        &:nth-child(6){
            margin-right: 0;
        }  
        &:last-child{
            margin-left: .7rem;
        }
    } 
}
.mappingBall_wx .item{
    color: #df0d15;
}

.add{
    font-size: 0.4rem;
    width: 0.22rem;
    height: 0.59rem;
    line-height: .59rem;
    margin: 0 .24rem;
    color: #646363;
}
</style>